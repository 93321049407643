import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '65, 200, 200',
		'primary-dark': '5, 94, 94',
		'accent': '249, 81, 81',
		'accent-plus': '255, 255, 255',
	},
});
